<template>
     <v-progress-linear
      :value="date == 'Caducado' ? 0 : setProgress(date)"
      height="25"
      :color="date == 'Caducado' ? red :setColor(date)"
    >
      <small>
        <strong>
          {{ date == 'Caducado' ? date : setValue(date) }}
        </strong>
      </small>
    </v-progress-linear> 
</template>

<script>
/**
* @author Yair Alvarez
* @company Erillam Healthcare
* @version 1.0
*/

export default {
  props: {
   date:{
      required: true,
      default: ''
    },  
  },
  data() {
    return {
      red: '#da1e37',
      orange: '#ff9500',
      yellow: '#ffc300',
      green: '#37BC7F',
    };
  },
  methods: {
    // Sets progress value
    setProgress ( date ) {
      let progress = 0;

      if ( date._data.years > 0 ) {
        progress = 100;
        return progress;
      }
      
      if ( date._data.months > 0 ) {
        progress = date._data.months * 8.333;
        return progress;
      }
      
      if ( date._data.days > 0 ) {
        progress = date._data.days * 0.277;
        return progress;
      }
    },

    // Sets calor value
    setColor ( date ) {
      let color = '';

      if ( date._data.years > 0 ) {
        color = this.green;
        return color;
      }
      
      if ( date._data.months >= 6 && date._data.months <= 12 ) {
        color = this.yellow;
        return color;
      }
      
      if ( date._data.months >= 0 && date._data.months < 6) {
        color = this.orange;
        return color;
      }

      if ( date._data.months < 0 ) { 
        color = this.red;
        return color;
      } 
    },

    // Sets left time value
    setValue ( date ) {
      let value = '';

      if ( date._data.years > 0 ) {
        value = value + this.yearFormat(date._data.years);

        if(date._data.months > 0) 
          value = value + this.monthFormat(date._data.months);
        
        if(date._data.days > 0)
        value = value + this.dayFormat(date._data.days);

        return value;
      }

      if ( date._data.months > 0 ) {
        value = value + this.monthFormat(date._data.months);
  
        if(date._data.days > 0) 
          value = value + this.dayFormat(date._data.days);

        return value;
      }
    
      if ( date._data.days > 0 ) {
        value = value + this.dayFormat(date._data.days);
        return value;
      }
    },

    yearFormat ( val ) {
      return val != 1 ? val + ' años ' : val + ' año ';
    },
    monthFormat ( val ) {
      return val != 1 ? val + ' meses ' : val + ' mes ';
    },
    dayFormat (val) {
      return val != 1 ? val + ' días' : val + ' día';
    },
  }

};
</script>